import React from 'react'
import ReactPlayer from 'react-player'
import Moment from 'react-moment'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { likeItem, unlikeItem, completeItem, incompleteItem, setCurrentItem, setPlaying } from '../actions'

class ItemComponent extends React.Component {
  state = { likesCount: this.props.defaultLikesCount };

  toggleLiked(e) {
    e.preventDefault()

    if (this.props.liked) {
      this.setState(state => ({
        likesCount: this.state.likesCount - 1
      }))

      this.props.dispatch(unlikeItem(this.props.userId, this.props.item.id))
    } else {
      this.setState(state => ({
        likesCount: this.state.likesCount + 1
      }))

      this.props.dispatch(likeItem(this.props.userId, this.props.item.id))
    }
  }

  toggleCompleted(e) {
    e.preventDefault()

    if (this.props.completed) {
      this.props.dispatch(incompleteItem(this.props.userId, this.props.item.id))
    } else {
      this.props.dispatch(completeItem(this.props.userId, this.props.item.id))
    }
  }

  playItem(e) {
    e.preventDefault()

    if (this.props.urlPlaying == this.props.item.attributes.url) {
      this.props.dispatch(setPlaying(true))
    } else {
      this.props.dispatch(setCurrentItem(this.props.item))
    }
  }

  pausePlaying(e) {
    e.preventDefault()

    this.props.dispatch(setPlaying(false))
  }

  render() {
    let completedClasses = ['fa']
    if ( this.props.completed ) {
      completedClasses = completedClasses.concat(['fa-check-square', 'text-success'])
    } else {
      completedClasses = completedClasses.concat(['fa-square', 'text-secondary'])
    }
    completedClasses = completedClasses.join(' ')

    let likedClasses = ['btn', 'btn-sm']
    if ( this.props.liked ) {
      likedClasses = likedClasses.concat(['btn-primary'])
    } else {
      likedClasses = likedClasses.concat(['btn-outline-secondary'])
    }
    likedClasses = likedClasses.join(' ')

    const backgroundImageStyle = {
      backgroundImage: `url(${this.props.item.attributes["fb-full-picture"]})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      height: '300px'
    }

    let playButton
    if (this.props.urlPlaying == this.props.item.attributes.url && this.props.isPlaying) {
      playButton = <a className="btn btn-outline-info" href="#" role="button" onClick={this.pausePlaying.bind(this)}><i className="fa fa-pause fa-5x"></i></a>
    } else {
      playButton = <a className="btn btn-outline-info" href="#" role="button" onClick={this.playItem.bind(this)}><i className="fa fa-play fa-5x"></i></a>
    }

    const likes = <span><i className="fa fa-thumbs-o-up" aria-hidden="true"></i> {this.state.likesCount}</span>

    let permalink
    if (this.props.item.attributes["fb-permalink-url"]) {
      permalink = <a href={this.props.item.attributes["fb-permalink-url"]} target="_blank">Origin url</a>
    }

    const likeButton = (
      <OverlayTrigger
        key="top"
        placement="top"
        overlay={
          <Tooltip id={`tooltip-top`}>
            {this.props.liked ? "Unlike" : "Like!"}
          </Tooltip>
        }
      >
        <span>
          <a href="#" className={likedClasses} onClick={this.toggleLiked.bind(this)}>
            <i className="fa fa-thumbs-up mr-2"></i>
            {this.state.likesCount}
          </a>
        </span>
      </OverlayTrigger>
    )

    const checkbox = (
      <OverlayTrigger
        key="top"
        placement="top"
        overlay={
          <Tooltip id={`tooltip-top`}>
            Mark as {this.props.completed ? "unplayed" : "played"}
          </Tooltip>
        }
      >
        <a href="#" onClick={this.toggleCompleted.bind(this)}><i className={completedClasses}></i></a>
      </OverlayTrigger>
    )

    return (
      <div className="col-md-6 col-lg-6 col-xl-3">
        <div className="card mb-4">
          <div className="card-image" style={backgroundImageStyle}>
            <div className="card-actions">
              {
                ReactPlayer.canPlay(this.props.item.attributes.url)
                ? playButton
                : null
              }
            </div>
          </div>
          <div className="card-body">
            <div><a href={this.props.item.attributes.url} target="_blank">{this.props.item.attributes["fb-name"]}</a></div>
            <Moment parse="YYYY-MM-DD h:mm:ss a" date={this.props.item.attributes["created-at-origin"]} format="DD-MM-YYYY HH:mm" />
            <div className="d-flex justify-content-between">
              { permalink }
              <div>
                {
                  this.props.userId
                    ? likeButton
                    : likes
                }
              </div>
              {
                this.props.userId
                ? checkbox
                : null
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ItemComponent
