import { connect } from 'react-redux'
import AppComponent from '../components/App'

const mapStateToProps = state => {
  const userId = state.userReducer.id

  return {
    userId
  }
}

const App = connect(
  mapStateToProps
)(AppComponent)

export default App
