import { connect } from 'react-redux'
import { setOrderBy } from '../actions'
import OrderBySelectComponent from '../components/OrderBySelect'

const mapStateToProps = state => {
  const field = state.itemsReducer.orderByField
  const direction = state.itemsReducer.orderByDirection
  const fieldAndDirection = field + '-' + direction

  return {
    fieldAndDirection: fieldAndDirection
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  setOrderBy: (orderBy) => {
    const fieldAndDirection = orderBy.split('-')
    const field = fieldAndDirection[0]
    const direction = fieldAndDirection[1]

    dispatch(setOrderBy({field: field, direction: direction}))
  }
})

const OrderBySelect = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderBySelectComponent)

export default OrderBySelect
