import React from 'react'

class OrderBySelectComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {value: props.fieldAndDirection}

    this.handleChange = this.handleChange.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    this.setState({value: nextProps.fieldAndDirection})
  }

  handleChange(event) {
    this.props.setOrderBy(event.target.value)
    this.setState({value: event.target.value})
  }

  render() {
    return (
      <div className="input-group mb-2 mr-sm-2 mb-sm-0">
        <select value={this.state.value} onChange={this.handleChange} id="order-by-select" className="form-control">
          <option value="date-desc">Date - Newest first</option>
          <option value="date-asc">Date - Oldest first</option>
          <option value="likes-desc">Likes</option>
        </select>
      </div>
    )
  }
}

export default OrderBySelectComponent
