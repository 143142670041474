import React from 'react'
import { fetchItems } from '../actions'
import Item from '../containers/Item'
import InfiniteScroll from 'react-infinite-scroller'

class ItemsIndexComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      hasMore: true,
      currentPage: 1,
      pageSize: 20,
      allItems: [],
      items: []
    }
  }

  componentWillMount() {
    this.props.dispatch(fetchItems(this.props.groupId, this.props.groupSource))
  }

  componentWillReceiveProps(nextProps) {
    this.setState(state => ({
      allItems: nextProps.items,
      items: nextProps.items.slice(0, this.state.pageSize),
      currentPage: nextProps.currentPage,
      hasMore: true
    }))
  }

  loadMore(page) {
    const begin = this.state.currentPage * this.state.pageSize
    const end = this.state.pageSize * (this.state.currentPage + 1)
    const items = this.state.items.concat(this.state.allItems.slice(begin, end))

    if ( items.length >= this.state.allItems.length ) {
      this.setState(state => ({
        hasMore: false
      }))
    }

    this.setState(state => ({
      items: items,
      currentPage: this.state.currentPage + 1
    }))
  }

  render() {
    const loader = <div className="loader">Loading ...</div>

    return (
      <div className="container-fluid">
        <InfiniteScroll
          pageStart={0}
          loadMore={this.loadMore.bind(this)}
          hasMore={this.state.hasMore}
          initialLoad={false}
          threshold={20}
          loader={loader}
          className="row"
        >
          {
            this.state.items.map((item, i) =>
              <Item key={item.id}
                    item={item}
                    groupSource={this.props.groupSource}
                    userId={this.props.userId}
                    completed={this.props.completedItems.includes(item.id)}
                    liked={this.props.likedItems.includes(item.id)}
                    defaultLikesCount={item.attributes["likes-count"]}
              />
            )
          }
        </InfiniteScroll>
      </div>
    )
  }
}

export default ItemsIndexComponent
