import { connect } from 'react-redux'
import FBLoginComponent from '../components/FBLogin'

const mapStateToProps = state => {
  const authenticated = state.userReducer.id != null
  const fbAppId = state.groupReducer.fbAppId

  return {
    authenticated,
    fbAppId
  }
}

const FBLogin = connect(
  mapStateToProps
)(FBLoginComponent)

export default FBLogin
