import React from 'react'
import { fetchToken } from '../actions'

class FBLoginComponent extends React.Component {
  responseFacebook(response) {
    if (response.accessToken) {
      this.props.dispatch(fetchToken(response.accessToken))
    }
  }

  render() {
    if (this.props.authenticated) {
      return (
        <span className="navbar-text">
          Logged in
        </span>
      )
    } else {
      return (
        <span>
          <a href="/login" className="btn btn-primary">Login with Email</a>
        </span>
      )
    }
  }
}

export default FBLoginComponent
