import { connect } from 'react-redux'
import { setGenreFilter } from '../actions'
import GenreSelectComponent from '../components/GenreSelect'

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (genre) => {
    dispatch(setGenreFilter(genre.toLowerCase()))
  }
})

const GenreSelect = connect(
  mapStateToProps,
  mapDispatchToProps
)(GenreSelectComponent)

export default GenreSelect
