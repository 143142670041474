import React from 'react'

class SearchInputComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {value: props.queryFilter}

    this.handleChange = this.handleChange.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    this.setState({value: nextProps.queryFilter})
  }

  handleChange(event) {
    this.props.setSearchQuery(event.target.value)
    this.setState({value: event.target.value})
  }

  render() {
    return (
      <input type="text" className="form-control mb-2 mr-sm-2 mb-sm-0" placeholder="Query" value={this.state.value} onChange={this.handleChange} />
    )
  }
}

export default SearchInputComponent
