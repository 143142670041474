import React from 'react'
import ReactDOM from 'react-dom'
import App from './containers/App'

import { createLogger } from 'redux-logger'
import { createStore, applyMiddleware, combineReducers } from 'redux'
import { Provider } from 'react-redux'
import { reducer as api } from 'redux-json-api'
import itemsReducer from './reducers/itemsReducer'
import likedItemsReducer from './reducers/likedItemsReducer'
import completedItemsReducer from './reducers/completedItemsReducer'
import playerReducer from './reducers/playerReducer'
import userReducer from './reducers/userReducer'
import groupReducer from './reducers/groupReducer'
import thunkMiddleware from 'redux-thunk'
import { setAxiosConfig } from 'redux-json-api'
import { setGroup, fetchToken } from './actions'

const reducer = combineReducers({
  api,
  itemsReducer,
  likedItemsReducer,
  completedItemsReducer,
  playerReducer,
  userReducer,
  groupReducer
})

let middleware = [thunkMiddleware]

if (process.env.NODE_ENV !== 'production') {
  const loggerMiddleware = createLogger()

  middleware = [...middleware, loggerMiddleware]
}

const store = createStore(reducer, applyMiddleware(...middleware))

store.dispatch(setAxiosConfig({
  baseURL: '/api'
}));

const node = document.getElementById('links')
const data = JSON.parse(node.getAttribute('data'))

store.dispatch(setGroup(data.group))

if (data.user === true) {
  store.dispatch(fetchToken(null))
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  node,
)
