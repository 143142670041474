import 'rc-slider/assets/index.css'
import 'rc-tooltip/assets/bootstrap.css'

import React from 'react'
import ReactPlayer from 'react-player'
import ReactDOM from 'react-dom'
import Slider from 'rc-slider'
import moment from 'moment'
import NProgress from 'react-nprogress'
import 'moment-duration-format'
import { toast } from 'react-toastify';

class PlayerComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      sliderValue: 0,
      seeking: false
    }

    this.onPlay = this.onPlay.bind(this)
    this.onPause = this.onPause.bind(this)
    this.onEnded = this.onEnded.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    this.setState(state => ({
      sliderValue: nextProps.progress.playedSeconds
    }))
  }

  startSeeking() {
    this.setState({ seeking: true })
  }

  setSliderValue(e) {
    this.setState({ sliderValue: e })
  }

  playerSeekTo = second => {
    this.setState({ seeking: false })
    this.player.seekTo(second)
  }

  onPlay(event) {
    NProgress.done()
    this.props.setIsPlaying(true)
  }

  onPause(event) {
    this.props.setIsPlaying(false)
  }

  onProgress = state => {
    if (!this.state.seeking) {
      this.props.setProgress(state)
    }
  }

  onReady() {
    NProgress.start()
  }

  onError() {
    toast.error('Media could not be played.')
    NProgress.done()
  }

  onEnded() {
    this.props.playNextItem(this.props.currentItem, this.props.items)
  }

  ref = player => {
    this.player = player
  }

  render() {
    const Handle = Slider.Handle

    const humanDuration = moment.duration(this.props.duration, "seconds").format("HH:mm:ss")

    const handle = (props) => {
      const { value, dragging, index, ...restProps } = props;
      return (
        <Handle value={value} {...restProps} />
      )
    }

    return (
      <div className="d-flex flex-row align-items-center">
        <div className="mr-3">
          <ReactPlayer
            ref={this.ref}
            url={this.props.currentItem.attributes.url}
            playing={this.props.playing}
            controls={false}
            height={1}
            width={1}
            onPlay={this.onPlay}
            onPause={this.onPause}
            onDuration={this.props.setDuration}
            onProgress={this.onProgress}
            onReady={this.onReady}
            onError={this.onError}
            onEnded={this.onEnded}
          />
          {
            this.props.currentItem.attributes.url && this.props.isPlaying == true
            ? <a className="btn btn-outline-info" href="#" role="button" onClick={this.props.pausePlaying.bind(this)}><i className="fa fa-pause"></i></a>
            : <a className="btn btn-outline-info" href="#" role="button" onClick={this.props.startPlaying.bind(this)}><i className="fa fa-play"></i></a>
          }
        </div>
        <div className="w-100 mr-3">
          <Slider
            min={0}
            max={Math.round(this.props.duration)}
            handle={handle}
            value={this.state.sliderValue}
            onBeforeChange={this.startSeeking.bind(this)}
            onChange={this.setSliderValue.bind(this)}
            onAfterChange={this.playerSeekTo.bind(this)}
          />
        </div>
        <div>
          {
            this.props.duration > 0
            ? humanDuration
            : "00:00:00"
          }
        </div>
      </div>
    )
  }
}

export default PlayerComponent
