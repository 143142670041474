import { connect } from 'react-redux'
import { setRandomItem } from '../actions'
import RandomButtonComponent from '../components/RandomButton'

const mapStateToProps = state => {
  const items = state.itemsReducer.items

  return {
    items
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  randomItem: (item) => {
    dispatch(setRandomItem(item))
  }
})

const RandomButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(RandomButtonComponent)

export default RandomButton
