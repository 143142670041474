import React from 'react'

class ResetButtonComponent extends React.Component {
  render() {
    return (
      <button type="button" className="btn btn-primary" onClick={e => {
          this.props.resetItems()
        }}>
        Reset
      </button>
    )
  }
}

export default ResetButtonComponent
