import { connect } from 'react-redux'
import ItemsIndexComponent from '../components/ItemsIndex'

const getFilteredByGenre = (data, genre) => {
  if (genre == 'all') {
    return data
  } else {
    return data.filter((l)=> {
      return l.attributes['genre-list'].includes(genre)
    })
  }
}

const getFilteredByQuery = (data, query) => {
  if (query != '') {
    return data.filter((l)=> {
      return l.attributes['fb-name'].toLowerCase().indexOf(query.toLowerCase()) > -1
    })
  } else {
    return data
  }
}

const getCompletedItems = (data, filter, completedItems) => {
  const flatCompletedItems = completedItems.map((i) => {
    return parseInt(i.id)
  })

  if ( filter == 'completed' ) {
    return data.filter((l)=> {
      return flatCompletedItems.includes(parseInt(l.id))
    })
  } else if ( filter == 'incompleted' ) {
    return data.filter((l)=> {
      return !flatCompletedItems.includes(parseInt(l.id))
    })
  } else {
    return data
  }
}

const sortByDate = (data, field, direction = 'desc') => {
  if (field == 'date') {
    data = data.sort((a,b) => {
      return new Date(a.attributes['created-at-origin']).getTime() - new Date(b.attributes['created-at-origin']).getTime()
    })
  } else if (field == 'likes') {
    data = data.sort((a,b) => {
      return a.attributes['likes-count'] - b.attributes['likes-count']
    })
  }

  if (direction == 'desc') {
    data = data.reverse()
  }

  return data
}

const mapStateToProps = state => {
  let items = state.itemsReducer.items

  if ( state.itemsReducer.randomItem != null ) {
    items = [state.itemsReducer.randomItem]
  } else if ( state.itemsReducer.items[0] && state.itemsReducer.items[0].attributes ) {
    // items = getFilteredByGenre(items, state.itemsReducer.genreFilter)
    items = getFilteredByQuery(items, state.itemsReducer.queryFilter)
    items = getCompletedItems(items, state.itemsReducer.completedFilter, state.completedItemsReducer.completedItems)
    items = sortByDate(items, state.itemsReducer.orderByField, state.itemsReducer.orderByDirection)
  }

  const likedItems = state.likedItemsReducer.likedItems.map((c)=> {
    return c.id
  })

  const completedItems = state.completedItemsReducer.completedItems.map((c)=> {
    return c.id
  })

  return {
    userId: state.userReducer.id,
    groupId: state.groupReducer.id,
    groupSource: state.groupReducer.source,
    currentPage: state.itemsReducer.currentPage,
    likedItems: likedItems,
    completedItems: completedItems,
    items: items
  }
}

const ItemsIndex = connect(
  mapStateToProps
)(ItemsIndexComponent)

export default ItemsIndex
