import React from 'react'

class RandomButtonComponent extends React.Component {
  render() {
    return (
      <button type="button" className="btn btn-primary" onClick={e => {
          this.props.randomItem(this.props.items[parseInt(Math.random() * this.props.items.length)])
        }}>
        Random!
      </button>
    )
  }
}

export default RandomButtonComponent
