import React from 'react'
import FBLogin from '../containers/FBLogin'

class NavbarComponent extends React.Component {
  render() {
    return (
      <nav className="navbar navbar-expand-md navbar-dark bg-dark d-flex justify-content-between">
        <a className="navbar-brand" href="#">{this.props.groupTitle}</a>
        <div>
          <a className="mr-3" href="/privacy">Privacy</a>
          <FBLogin />
        </div>
      </nav>
    )
  }
}

export default NavbarComponent
