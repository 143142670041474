export default function reducer(state = {
  likedItems: []
}, action) {
  switch (action.type) {
    case 'RECEIVE_LIKED_ITEMS':
      return {...state, likedItems: action.likedItems}
    case 'ADD_LIKED_ITEM':
      return {...state, likedItems: state.likedItems.concat([{type: 'items', id: action.itemId}])}
    case 'REMOVE_LIKED_ITEM':
      return {...state, likedItems: state.likedItems.filter((i)=> {
        return i.id != action.itemId
      })}
    default:
      return state;
  }
}
