import { connect } from 'react-redux'
import { setAllItems } from '../actions'
import ResetButtonComponent from '../components/ResetButton'

const mapStateToProps = state => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
  resetItems: () => {
    dispatch(setAllItems())
  }
})

const ResetButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetButtonComponent)

export default ResetButton
