import React from 'react'

const GenreSelectComponent = ({ onChange }) => {
  return (
    <div className="input-group mb-2 mr-sm-2 mb-sm-0">
      <select onChange={e => {
          e.preventDefault()
          onChange(e.target.value)
        }} id="genre-select" className="form-control">
        <option>All</option>
        <option>Techno</option>
        <option>DnB</option>
      </select>
    </div>
  );
}

export default GenreSelectComponent
