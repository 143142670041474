import { connect } from 'react-redux'
import { setPlaying, setIsPlaying, setDuration, setProgress, setCurrentItem } from '../actions'
import PlayerComponent from '../components/Player'

const mapStateToProps = state => {
  const items = state.itemsReducer.items
  const { currentItem, playing, isPlaying, duration, progress } = state.playerReducer

  return {
    items,
    currentItem,
    playing,
    isPlaying,
    duration,
    progress
  }
}
const mapDispatchToProps = (dispatch, ownProps) => ({
  startPlaying: (e) => {
    e.preventDefault()

    dispatch(setPlaying(true))
  },
  pausePlaying: (e) => {
    e.preventDefault()

    dispatch(setPlaying(false))
  },
  setIsPlaying: (isPlaying) => {
    dispatch(setIsPlaying(isPlaying))
  },
  setDuration: (duration) => {
    dispatch(setDuration(duration))
  },
  setProgress: (progress) => {
    dispatch(setProgress(progress))
  },
  playNextItem: (currentItem, items) => {
    const nextIndex = items.indexOf(currentItem) + 1

    if ( nextIndex in items ) {
      dispatch(setCurrentItem(items[nextIndex]))
    }
  }
})

const Player = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerComponent)

export default Player
