export default function reducer(state = {
  id: null,
  title: null,
  source: null,
  fbAppId: null,
}, action) {
  switch (action.type) {
    case 'SET_GROUP':
      return { ...state, id: action.id, title: action.title, source: action.source, fbAppId: action.fbAppId }
    default:
      return state;
  }
}
