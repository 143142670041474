export default function reducer(state = {
  currentItem: { attributes: { url: null } },
  playing: true,
  isPlaying: false,
  duration: 0,
  progress: {
    played: 0,
    playedSeconds: 0
  }
}, action) {
  switch (action.type) {
    case 'SET_CURRENT_ITEM':
      return {...state, currentItem: action.currentItem}
    case 'SET_PLAYING':
      return {...state, playing: action.playing}
    case 'SET_IS_PLAYING':
      return {...state, isPlaying: action.isPlaying}
    case 'SET_DURATION':
      return {...state, duration: action.duration}
    case 'SET_PROGRESS':
      return {...state, progress: action.progress}
    default:
      return state;
  }
}
