import React from 'react'

class CompletedSelectComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {value: props.completedFilter}

    this.handleChange = this.handleChange.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    this.setState({value: nextProps.completedFilter})
  }

  handleChange(event) {
    this.props.setCompletedFilter(event.target.value)
    this.setState({value: event.target.value})
  }

  render() {
    return (
      <div className="input-group mb-2 mr-sm-2 mb-sm-0">
        <select value={this.state.value} onChange={this.handleChange} className="form-control">
          <option value="all">All</option>
          <option value="completed">Played</option>
          <option value="incompleted">Not yet played</option>
        </select>
      </div>
    )
  }
}

export default CompletedSelectComponent
