export default function reducer(state = {
  items: [],
  genreFilter: 'all',
  queryFilter: '',
  completedFilter: 'all',
  orderByField: 'date',
  orderByDirection: 'desc',
  currentPage: 1,
  randomItem: null
}, action) {
  switch (action.type) {
    case 'SET_GENRE_FILTER':
      return {...state, genreFilter: action.filter, currentPage: 1, randomItem: null}
    case 'SET_SEARCH_QUERY':
      return {...state, queryFilter: action.query, currentPage: 1, randomItem: null}
    case 'SET_COMPLETED_FILTER':
      return {...state, completedFilter: action.filter, currentPage: 1, randomItem: null}
    case 'SET_ORDER_BY':
      return {...state, orderByField: action.orderBy.field, orderByDirection: action.orderBy.direction, randomItem: null}
    case 'RECEIVE_ITEMS':
      return { ...state, items: action.items}
    case 'APPEND_ITEMS':
      return { ...state, items: state.items.concat(action.items)}
    case 'SET_RANDOM_ITEM':
      return {...state, randomItem: action.item, queryFilter: '', completedFilter: 'all', orderByField: 'date', orderByDirection: 'desc', currentPage: 1}
    case 'SET_ALL_ITEMS':
      return {...state, queryFilter: '', completedFilter: 'all', orderByField: 'date', orderByDirection: 'desc', currentPage: 1, randomItem: null}
    default:
      return state;
  }
}
