import { readEndpoint, updateResource, setAxiosConfig } from 'redux-json-api';
import axios from 'axios';

export const setGenreFilter = (filter) => ({
  type: 'SET_GENRE_FILTER',
  filter
})

export const setSearchQuery = (query) => ({
  type: 'SET_SEARCH_QUERY',
  query
})

export const setCompletedFilter = (filter) => ({
  type: 'SET_COMPLETED_FILTER',
  filter
})

export const setOrderBy = (orderBy) => ({
  type: 'SET_ORDER_BY',
  orderBy
})

export const setCurrentItem = (currentItem) => ({
  type: 'SET_CURRENT_ITEM',
  currentItem
})

export const setPlaying = (playing) => ({
  type: 'SET_PLAYING',
  playing
})

export const setIsPlaying = (isPlaying) => ({
  type: 'SET_IS_PLAYING',
  isPlaying
})

export const setDuration = (duration) => ({
  type: 'SET_DURATION',
  duration
})

export const setProgress = (progress) => ({
  type: 'SET_PROGRESS',
  progress
})

export const setRandomItem = (item) => ({
  type: 'SET_RANDOM_ITEM',
  item
})

export const setAllItems = (items) => ({
  type: 'SET_ALL_ITEMS',
  items
})

function receiveItems(json) {
  return {
    type: 'RECEIVE_ITEMS',
    items: json.data,
    receivedAt: Date.now()
  }
}

function appendItems(json) {
  return {
    type: 'APPEND_ITEMS',
    items: json.data,
    receivedAt: Date.now()
  }
}

function receiveToken(token) {
  return {
    type: 'RECEIVE_TOKEN',
    id: token.id,
    jwt: token.jwt,
    receivedAt: Date.now()
  }
}

function receiveLikedItems(likedItems) {
  return {
    type: 'RECEIVE_LIKED_ITEMS',
    likedItems: likedItems,
    receivedAt: Date.now()
  }
}

function addLikedItem(itemId) {
  return {
    type: 'ADD_LIKED_ITEM',
    itemId: itemId,
    receivedAt: Date.now()
  }
}

function removeLikedItem(itemId) {
  return {
    type: 'REMOVE_LIKED_ITEM',
    itemId: itemId,
    receivedAt: Date.now()
  }
}

function receiveCompletedItems(completedItems) {
  return {
    type: 'RECEIVE_COMPLETED_ITEMS',
    completedItems: completedItems,
    receivedAt: Date.now()
  }
}

function addCompletedItem(itemId) {
  return {
    type: 'ADD_COMPLETED_ITEM',
    itemId: itemId,
    receivedAt: Date.now()
  }
}

function removeCompletedItem(itemId) {
  return {
    type: 'REMOVE_COMPLETED_ITEM',
    itemId: itemId,
    receivedAt: Date.now()
  }
}

export function setGroup(group) {
  return {
    type: 'SET_GROUP',
    id: group.id,
    title: group.title,
    source: group.source,
    fbAppId: group.fbAppId
  }
}

export function fetchItemsForGenre(genre) {
  return function (dispatch) {
    return dispatch(readEndpoint('items.json?sort=-created_at_origin&filter[genre]='+genre+'&filter[fb_type]=link,video'))
           .then(json =>
             dispatch(receiveItems(json))
           )
  }
}

export function fetchItems(groupId, groupSource) {
  let url = 'items.json?sort=-created_at_origin&filter[group_id]=' + groupId

  if (groupSource == 'facebook') {
    url += '&filter[fb_type]=link,video'
  }

  return function (dispatch) {
    return dispatch(readEndpoint(url))
           .then(json =>
             dispatch(receiveItems(json.body))
           )
  }
}

export function fetchToken(token) {
  return function (dispatch) {
    return axios.post('/user_token', { access_token: token })
           .then(json => {
             dispatch(setAxiosConfig({
               baseURL: '/api',
               headers: {
                 'Authorization': 'Bearer ' + json.data.jwt,
                 'X-CSRF-Token': document.getElementsByName("csrf-token")[0].content
               }
             }));
             axios.defaults.headers.common['Authorization'] = 'Bearer ' + json.data.jwt
             axios.defaults.headers.common['X-CSRF-Token'] = document.getElementsByName("csrf-token")[0].content
             dispatch(receiveToken(json.data))
             dispatch(fetchLikedItems(json.data.id))
             dispatch(fetchCompletedItems(json.data.id))
           })
  }
}

export function fetchLikedItems(userId) {
  return function (dispatch) {
    return dispatch(readEndpoint('users/'+userId+'/relationships/liked-items.json'))
           .then(json =>
             dispatch(receiveLikedItems(json.body.data))
           )
  }
}

export function likeItem(userId, itemId) {
  return function (dispatch) {
    return axios.post('/api/users/'+userId+'/relationships/liked-items.json',
           { data: [{ type: 'items', id: itemId }] },
           { headers: { 'Content-Type': 'application/vnd.api+json' } })
           .then(json => {
             dispatch(addLikedItem(itemId))
           })
  }
}

export function unlikeItem(userId, itemId) {
  return function (dispatch) {
    return axios.delete('/api/users/'+userId+'/relationships/liked-items.json',
           { data: { data: [{ type: 'items', id: itemId }] } })
           .then(json => {
             dispatch(removeLikedItem(itemId))
           })
  }
}

export function fetchCompletedItems(userId) {
  return function (dispatch) {
    return dispatch(readEndpoint('users/'+userId+'/relationships/items.json'))
           .then(json =>
             dispatch(receiveCompletedItems(json.body.data))
           )
  }
}

export function completeItem(userId, itemId) {
  return function (dispatch) {
    return axios.post('/api/users/'+userId+'/relationships/items.json',
           { data: [{ type: 'items', id: itemId }] },
           { headers: { 'Content-Type': 'application/vnd.api+json' } })
           .then(json => {
             dispatch(addCompletedItem(itemId))
           })
  }
}

export function incompleteItem(userId, itemId) {
  return function (dispatch) {
    return axios.delete('/api/users/'+userId+'/relationships/items.json',
           { data: { data: [{ type: 'items', id: itemId }] } })
           .then(json => {
             dispatch(removeCompletedItem(itemId))
           })
  }
}
