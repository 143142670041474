import { connect } from 'react-redux'
import { setSearchQuery } from '../actions'
import SearchInputComponent from '../components/SearchInput'

const mapStateToProps = state => {
  const queryFilter = state.itemsReducer.queryFilter

  return {
    queryFilter: queryFilter
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  setSearchQuery: (query) => {
    dispatch(setSearchQuery(query))
  }
})

const SearchInput = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchInputComponent)

export default SearchInput
