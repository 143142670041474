import { connect } from 'react-redux'
import { setCompletedFilter } from '../actions'
import CompletedSelectComponent from '../components/CompletedSelect'

const mapStateToProps = state => {
  const completedFilter = state.itemsReducer.completedFilter

  return {
    completedFilter: completedFilter
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  setCompletedFilter: (completedFilter) => {
    dispatch(setCompletedFilter(completedFilter))
  }
})

const CompletedSelect = connect(
  mapStateToProps,
  mapDispatchToProps
)(CompletedSelectComponent)

export default CompletedSelect
