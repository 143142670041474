import { connect } from 'react-redux'
import NavbarComponent from '../components/Navbar'

const mapStateToProps = state => {
  return {
    groupId: state.groupReducer.id,
    groupTitle: state.groupReducer.title,
    groupSource: state.groupReducer.source,
  }
}

const Navbar = connect(
  mapStateToProps
)(NavbarComponent)

export default Navbar
