export default function reducer(state = {
  completedItems: []
}, action) {
  switch (action.type) {
    case 'RECEIVE_COMPLETED_ITEMS':
      return {...state, completedItems: action.completedItems}
    case 'ADD_COMPLETED_ITEM':
      return {...state, completedItems: state.completedItems.concat([{type: 'items', id: action.itemId}])}
    case 'REMOVE_COMPLETED_ITEM':
      return {...state, completedItems: state.completedItems.filter((i)=> {
        return i.id != action.itemId
      })}
    default:
      return state;
  }
}
