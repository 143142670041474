export default function reducer(state = {
  id: null,
  jwt: null
}, action) {
  switch (action.type) {
    case 'RECEIVE_TOKEN':
      return {...state, id: action.id, jwt: action.jwt}
    default:
      return state;
  }
}
