import { connect } from 'react-redux'
import ItemComponent from '../components/Item'

const mapStateToProps = state => {
  const urlPlaying = state.playerReducer.currentItem.attributes.url
  const isPlaying = state.playerReducer.isPlaying

  return {
    urlPlaying,
    isPlaying
  }
}

const Item = connect(
  mapStateToProps
)(ItemComponent)

export default Item
