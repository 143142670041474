import React from 'react'
import Navbar from '../containers/Navbar'
import GenreSelect from '../containers/GenreSelect'
import SearchInput from '../containers/SearchInput'
import CompletedSelect from '../containers/CompletedSelect'
import ItemsIndex from '../containers/ItemsIndex'
import OrderBySelect from '../containers/OrderBySelect'
import Player from '../containers/Player'
import RandomButton from '../containers/RandomButton'
import ResetButton from '../containers/ResetButton'
import { ToastContainer } from 'react-toastify';

class AppComponent extends React.Component {
  render() {
    return (
      <div>
        <ToastContainer />
        <Navbar />
        <div className="container-fluid">
          <div className="row">
            <div className="col my-3 d-flex justify-content-between">
              <form className="form-inline">
                <SearchInput />
                <OrderBySelect />
                {
                  this.props.userId != null
                  ? <CompletedSelect />
                  : null
                }
                <ResetButton />
              </form>
              <RandomButton />
            </div>
          </div>
        </div>
        <ItemsIndex />
        <footer className="footer">
          <div className="container-fluid">
            <Player />
          </div>
        </footer>
      </div>
    )
  }
}

export default AppComponent
